export default {
  methods: {
    checkTotalPermissoes(total) {
      let tableTotal = this.bitWiseTableTotal;
      return total & tableTotal ? true : false;
    },
    checkVisualizar(total) {
      let visualizar = this.$store.state.bitWiseTable.visualizar;
      return visualizar & total ? true : false;
    },
    checkAdicionar(total) {
      let adicionar = this.$store.state.bitWiseTable.adicionar;
      return adicionar & total ? true : false;
    },
    checkEditar(total) {
      let editar = this.$store.state.bitWiseTable.editar;
      return editar & total ? true : false;
    },
    checkDeletar(total) {
      let deletar = this.$store.state.bitWiseTable.deletar;
      return deletar & total ? true : false;
    },
    checkImprimir(total) {
      let imprimir = this.$store.state.bitWiseTable.imprimir;
      return imprimir & total ? true : false;
    },
    checkExportar(total) {
      let exportar = this.$store.state.bitWiseTable.exportar;
      return exportar & total ? true : false;
    },
    checkLimitar(total) {
      let limitar = this.$store.state.bitWiseTable.limitar;
      return limitar & total ? true : false;
    },
    checkMenu(total) {
      let menu = this.$store.state.bitWiseTable.menu;
      return menu & total ? true : false;
    }
  }
};

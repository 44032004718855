<template>
  <v-row v-if="vuePermissoesUsuario">
    <v-col cols="12">
      <v-card class="pa-3 d-md-flex align-center justify-space-between" flat>
        <v-card-text class="body-2 pa-0">
          {{ $tc("permissoes.copiar") }}:
        </v-card-text>
        <v-select
          class="grow body-2 mt-4 mt-md-0"
          v-model="selectedUserId"
          :items="usuarios"
          item-text="name"
          item-value="id"
          :placeholder="$tc('msg.selecioneusuario')"
          v-on:change="getCopyPermissoes"
          single-line
          dense
          hide-details
          clearable
        ></v-select>
        <v-btn
          depressed
          class="mt-6 mt-md-0 ml-md-4"
          @click.prevent="copiarPermissoes()"
        >
          {{ $tc("global.copiar") }}
        </v-btn>
      </v-card>
    </v-col>

    <v-col cols="12" class="pb-8">
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$tc('global.buscar')"
        clearable
        single-line
        outlined
        dense
        hide-details
        class="mb-4"
      ></v-text-field>
      <v-treeview
        v-if="!loading"
        :active.sync="active"
        :open.sync="open"
        :items="permissoes"
        :search="search"
        @update:active="getPermissao()"
        item-text="descricao"
        item-key="id"
        item-children="children"
        transition
        activatable
        hoverable
        dense
        expand-icon="keyboard_arrow_down"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon>{{ open ? "folder_open" : "folder" }}</v-icon>
        </template>
        <template v-slot:label="{ item }">
          <div class="body-2">{{ item.descricao }}</div>
        </template>
        <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append="{ item }">
          <v-divider vertical class="mx-3"></v-divider>
          <span class="caption">{{ item.descricao_detalhe }}</span>
        </template>
      </v-treeview>
      <Carregando v-else />
    </v-col>

    <v-dialog v-model="dialog" max-width="350px">
      <v-card>
        <v-card-title class="text-body-1 lightgray">
          {{ permissao.descricao }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <!-- <v-card-text class="pa-6">
            {{ permissao.descricao_detalhe }}
          </v-card-text> -->
        <v-card-text class="pa-6" v-if="checkTotalPermissoes(permissao.bits)">
          <template v-if="!loadingBit">
            <v-card
              flat
              class="d-flex aling-center justify-space-between my-2 px-4 py-2"
              v-if="checkMenu(permissao.bits)"
              color="lightgray"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-2 font-weight-medium text-uppercase"
              >
                Menu
              </v-subheader>

              <v-switch
                class="ma-0"
                hide-details
                :false-value="0"
                :true-value="128"
                v-model="bitWise.menu"
              ></v-switch>
            </v-card>

            <v-card
              flat
              class="d-flex aling-center justify-space-between my-2 px-4 py-2"
              v-if="checkVisualizar(permissao.bits)"
              color="lightgray"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-2 font-weight-medium text-uppercase"
              >
                {{ $tc("botoes.visualizar") }}
              </v-subheader>

              <v-switch
                class="ma-0"
                hide-details
                :false-value="0"
                :true-value="1"
                v-model="bitWise.visualizar"
              ></v-switch>
            </v-card>

            <v-card
              flat
              class="d-flex aling-center justify-space-between my-2 px-4 py-2"
              v-if="checkAdicionar(permissao.bits)"
              color="lightgray"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-2 font-weight-medium text-uppercase"
              >
                {{ $tc("global.add") }}
              </v-subheader>

              <v-switch
                class="ma-0"
                hide-details
                :false-value="0"
                :true-value="2"
                v-model="bitWise.adicionar"
              ></v-switch>
            </v-card>

            <v-card
              flat
              class="d-flex aling-center justify-space-between my-2 px-4 py-2"
              v-if="checkEditar(permissao.bits)"
              color="lightgray"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-2 font-weight-medium text-uppercase"
              >
                {{ $tc("botoes.editar") }}
              </v-subheader>

              <v-switch
                class="ma-0"
                hide-details
                :false-value="0"
                :true-value="4"
                v-model="bitWise.editar"
              ></v-switch>
            </v-card>

            <v-card
              flat
              class="d-flex aling-center justify-space-between my-2 px-4 py-2"
              v-if="checkDeletar(permissao.bits)"
              color="lightgray"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-2 font-weight-medium text-uppercase"
              >
                {{ $tc("botoes.del") }}
              </v-subheader>

              <v-switch
                class="ma-0"
                hide-details
                :false-value="0"
                :true-value="8"
                v-model="bitWise.deletar"
              ></v-switch>
            </v-card>

            <v-card
              flat
              class="d-flex aling-center justify-space-between my-2 px-4 py-2"
              v-if="checkImprimir(permissao.bits)"
              color="lightgray"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-2 font-weight-medium text-uppercase"
              >
                {{ $tc("botoes.imprimir") }}
              </v-subheader>

              <v-switch
                class="ma-0"
                hide-details
                :false-value="0"
                :true-value="16"
                v-model="bitWise.imprimir"
              ></v-switch>
            </v-card>

            <v-card
              flat
              class="d-flex aling-center justify-space-between my-2 px-4 py-2"
              v-if="checkExportar(permissao.bits)"
              color="lightgray"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-2 font-weight-medium text-uppercase"
              >
                {{ $tc("botoes.exportar") }}
              </v-subheader>

              <v-switch
                class="ma-0"
                hide-details
                :false-value="0"
                :true-value="32"
                v-model="bitWise.exportar"
              ></v-switch>
            </v-card>

            <v-card
              flat
              class="d-flex aling-center justify-space-between my-2 px-4 py-2"
              v-if="checkLimitar(permissao.bits)"
              color="lightgray"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-2 font-weight-medium text-uppercase"
              >
                {{ $tc("botoes.limitar") }}
              </v-subheader>

              <v-switch
                class="ma-0"
                hide-details
                :false-value="0"
                :true-value="64"
                v-model="bitWise.limitar"
              ></v-switch>
            </v-card>
          </template>
          <Carregando v-else />
        </v-card-text>
        <v-card-text class="pa-6" v-else>
          {{ $tc("msg.nenhumapermissao") }}
        </v-card-text>

        <v-card-text class="py-0 mb-6" v-if="bitWise.limitar">
          <v-row>
            <v-col>
              <v-text-field
                v-model="userPermissao.valor"
                :label="$tc('permissoes.valor')"
                type="number"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="userPermissao.operador"
                :label="$tc('permissoes.operador')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>

          <v-btn
            @click.prevent="updateUserPermission(userPermissao.id)"
            depressed
            small
            class="white--text"
            color="buttons"
          >
            {{ $tc("botoes.salvar") }}
            <v-icon right>save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <NaoAutorizado v-else />
</template>

<script>
import {
  getPermissoes,
  getPermissao,
  getUsuarioPermissoes,
  putPermissaoUsuario,
  postPermissaoUsuario,
} from "@/services/api/permissoes.api.js";
import { getUsuarios } from "@/services/api/usuarios.api.js";
import updateBitMask from "@/mixins/configuracoes/permissoes/updateBitMask.js";
import checkPermissions from "@/mixins/configuracoes/permissoes/checkPermissions.js";
import copyUserPermissions from "@/mixins/configuracoes/permissoes/copyUserPermissions.js";

import { mapGetters } from "vuex";

export default {
  name: "UsuarioPermissoes",
  mixins: [updateBitMask, checkPermissions, copyUserPermissions],
  props: ["usuarioId"],
  data: () => ({
    search: "",
    dialog: false,
    active: [],
    open: [],
    permissoes: [],
    permissao: {
      bits: 0,
    },
    original: {},
    userPermissoes: [],
    copiaUserPermissoes: [],
    userPermissao: {
      nivel_acesso: 0,
      valor: null,
      operador: null,
    },
    usuarios: [],
    selectedUserId: null,
    loading: false,
    loadingBit: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vuePermissoesUsuario() {
      return this.getAccess("vuePermissoesUsuario", "visualizar");
    },
    userId() {
      return this.usuarioId;
    },
  },
  methods: {
    goToPermissao(item) {
      this.$router.push({
        name: "Permissao",
        params: { permissaoId: item },
      });
    },
    getPermissaoPai(permissao_id) {
      let permissao_pai = "";
      this.permissoes.forEach((permissao) => {
        if (permissao.id == permissao_id) {
          permissao_pai = ". " + permissao.descricao;
        }
      });
      return permissao_pai;
    },
    getPermissao() {
      this.bitWise.visualizar = 0;
      this.bitWise.adicionar = 0;
      this.bitWise.editar = 0;
      this.bitWise.deletar = 0;
      this.bitWise.imprimir = 0;
      this.bitWise.exportar = 0;
      this.bitWise.limitar = 0;
      this.bitWise.menu = 0;
      this.userPermissao = {};

      if (this.active[0]) {
        this.$Progress.start();
        getPermissao(this.active[0])
          .then((response) => {
            this.permissao = response;
            this.dialog = true;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.$Progress.fail();
            console.error(error);
          });
        if (this.userPermissoes.length) {
          this.loadingBit = true;
          this.userPermissoes.forEach((permissao) => {
            console.log("teste");
            if (this.active[0] === permissao.permissao_id) {
              this.userPermissao = permissao;
              this.updateBitMasks(this.userPermissao.nivel_acesso).then(() => {
                this.loadingBit = false;
              });
              this.original = { ...permissao };
            } else {
              this.loadingBit = false;
            }
          });
        }
      }
    },
    getUsuarioPermissoes(userId) {
      if (userId) {
        getUsuarioPermissoes(userId)
          .then((response) => {
            this.userPermissoes = response;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.notFound = "Permissao nao Econtrada";
            }
            console.error(error);
          });
      }
    },
    updateUserPermission(id) {
      this.userPermissao.nivel_acesso =
        this.bitWise.visualizar +
        this.bitWise.adicionar +
        this.bitWise.editar +
        this.bitWise.deletar +
        this.bitWise.imprimir +
        this.bitWise.exportar +
        this.bitWise.limitar +
        this.bitWise.menu;

      if (this.bitWise.limitar === 0) {
        this.userPermissao.valor = null;
        this.userPermissao.operador = null;
      }

      let permIgual = null;

      if (this.userPermissoes) {
        permIgual = this.userPermissoes.find(
          (perm) => perm.permissao_id === this.permissao.id
        );
      }

      this.$Progress.start();

      if (permIgual) {
        if (this.lodash.isEqual(this.userPermissao, this.original)) {
          this.$toast.error(this.$tc("msg.fazermudanca"));
          this.$Progress.fail();
        } else {
          putPermissaoUsuario(id, this.userPermissao)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success(this.$tc("msg.atualizadosucesso"));
                this.original = { ...this.userPermissao };
                this.getUsuarioPermissoes(this.userId);
                this.dialog = false;
                this.$Progress.finish();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$Progress.fail();
            });
        }
      } else {
        postPermissaoUsuario(this.userPermissao, this.userId, this.permissao.id)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success(this.$tc("msg.criadosucesso"));
              this.original = { ...this.userPermissao }; //
              this.getUsuarioPermissoes(this.userId);
              this.dialog = false;
              this.$Progress.finish();
            }
          })
          .catch((error) => {
            console.log(error.response);
            this.$Progress.fail();
          });
      }
    },
    getUsuarios() {
      getUsuarios()
        .then((response) => {
          this.usuarios = response;
          let removeIndex = this.usuarios
            .map((item) => item.id)
            .indexOf(this.userId);
          this.usuarios.splice(removeIndex, 1);
        })
        .catch((error) => console.log(error));
    },
    getPermissoes() {
      this.loading = true;
      this.$Progress.start();
      getPermissoes()
        .then((response) => {
          this.permissoes = response;
          this.permissoes.forEach((permissao) => {
            this.open.push(permissao.id);
            permissao.children.forEach((child) => {
              this.open.push(child.id);
            });
          });
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.$Progress.fail();
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getPermissoes();
    this.getUsuarioPermissoes(this.userId);
    this.getUsuarios();
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-treeview-node {
  cursor: pointer;
  // background: #f9f9f9;
  margin: 10px 0;
  border-radius: 4px;
  // border: thin solid rgba(0, 0, 0, 0.08);

  .v-treeview-node__children {
    .v-treeview-node {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: thin solid rgba(0, 0, 0, 0.08);
      border-radius: 0px;
      margin: 0;

      &:first-of-type {
        border-top: thin solid rgba(0, 0, 0, 0.08);
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
</style>

import {
  getUsuarioPermissoes,
  putPermissaoUsuario,
  postPermissaoUsuario,
} from "@/services/api/permissoes.api.js";

export default {
  data: () => ({
    zeradoLoading: false,
    updateLoading: false,
    newLoading: false,
  }),
  methods: {
    getCopyPermissoes(userId) {
      if (userId) {
        getUsuarioPermissoes(userId)
          .then((response) => {
            this.copiaUserPermissoes = response;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$toast.error("Erro ao selecionar usuario");
      }
    },
    copiarPermissoes() {
      this.zeradoLoading = true;
      this.updateLoading = true;
      this.newLoading = true;

      if (this.copiaUserPermissoes.length) {
        this.userPermissoes.forEach((original, index) => {
          let id = original.permissao_id;

          original.nivel_acesso = 0;
          original.valor = null;
          original.operador = null;

          putPermissaoUsuario(original.id, original)
            .then(() => {
              if (index + 1 === this.userPermissoes.length) {
                this.zeradoLoading = false;
                console.log("zerei as permissoes");
              }
            })
            .catch((error) => {
              console.log(error);
            });

          if (index + 1 === this.userPermissoes.length) {
            this.zerado = true;
          }
        });

        let contador = 0;

        this.copiaUserPermissoes.forEach((permissao, index) => {
          let objIgual = this.userPermissoes.find(
            (obj) => obj.permissao_id === permissao.permissao_id
          );

          if (objIgual) {
            if (objIgual.permissao_id === permissao.permissao_id) {
              let data = {
                nivel_acesso: permissao.nivel_acesso,
                valor: permissao.valor,
                operador: permissao.operador,
              };

              contador++;

              putPermissaoUsuario(objIgual.id, data)
                .then(() => {
                  if (index + 1 === this.copiaUserPermissoes.length) {
                    this.updateLoading = false;
                    this.getUsuarioPermissoes(this.userId);
                    console.log("atualizei as permissoes");
                    this.$toast.success("Permissoes atualizadas com sucesso!");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            let data = {
              nivel_acesso: permissao.nivel_acesso,
              valor: permissao.valor,
              operador: permissao.operador,
            };

            postPermissaoUsuario(data, this.userId, permissao.permissao_id)
              .then(() => {
                if (index + 1 === this.copiaUserPermissoes.length - contador) {
                  this.newLoading = false;
                  console.log("criei as permissoes");
                  this.getUsuarioPermissoes(this.userId);
                  this.$toast.success("Permissoes criadas com sucesso!");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      } else {
        this.$toast.error("Este usuario nao contem permissoes");
        this.zeradoLoading = false;
        this.updateLoading = false;
        this.newLoading = false;
      }
    },
  },
};
